import React from "react";
import { Box, Typography } from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import { colors } from "../../lib/theme";

const PaymentInformation = () => (
  <>
    <Typography variant="h5" gutterBottom sx={{ mt: 6, color: colors.navy }}>
      Payment Information
    </Typography>
    <Typography variant="body1" gutterBottom color={colors.navy} sx={{ mb: 3 }}>
      Please enter your payment information to complete the transaction.
    </Typography>
    <Box
      sx={{
        mt: 3,
        border: `1px solid ${colors.lighterGray}`,
        borderRadius: '4px',
        padding: '15px',
        backgroundColor: colors.white,
      }}
    >
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: colors.navy,
              '::placeholder': {
                color: '#aab7c4',
              },
            },
          },
        }}
      />
    </Box>
  </>
);

export default PaymentInformation;
